import { Element } from "~/components/form/ElementSelect";
import apiClient from "~/lib/apiClient";
import { mutate } from "swr";

export type SimplePaginatedResponse<T> = {
  data: T[];
  meta: {
    current_page: number;
    per_page: number;
  };
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
};

export const ELEMENTS_KEY = '/convert/elements';

export async function fetchElements(): Promise<SimplePaginatedResponse<Element>> {
  const response = await apiClient.get(ELEMENTS_KEY);
  return response.data;
}

export async function createElement(element: Omit<Element, 'id'>): Promise<Element> {
  const response = await apiClient.post(ELEMENTS_KEY, element);
  await mutate(ELEMENTS_KEY);
  return response.data;
}

export async function updateElement(id: string, element: Partial<Element>): Promise<Element> {
  const response = await apiClient.patch(`${ELEMENTS_KEY}/${id}`, element);
  await mutate(ELEMENTS_KEY);
  return response.data;
}

export async function deleteElement(id: string): Promise<void> {
  await apiClient.delete(`${ELEMENTS_KEY}/${id}`);
  await mutate(ELEMENTS_KEY);
}

export async function fetchElement(id: string): Promise<Element> {
  const response = await apiClient.get(`${ELEMENTS_KEY}/${id}`);
  return response.data;
} 