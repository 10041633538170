import {useParams} from "react-router";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import {Button, Heading, Card, Text, Badge, Table, Dialog, Switch, Flex} from "@radix-ui/themes";
import {Link} from "react-router-dom";
import {get} from "lodash";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {ArrowDownRightIcon, EyeIcon} from "@heroicons/react/16/solid";
import classNames from "classnames";
import AnchorButton from "~/components/AnchorButton";
import { ArrowRightIcon, BadgeDollarSignIcon, CheckCircle, CircleDollarSignIcon, MousePointerClickIcon, ShoppingCartIcon, TestTubeIcon, ViewIcon, CloudLightning, TextCursorIcon, CodeIcon, CloudLightningIcon } from "lucide-react";
import { PlusIcon } from "lucide-react";
import { ArrowUpRightIcon, EditIcon } from "lucide-react";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import update from "immutability-helper";

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

export default function WorkflowPaywallsPage() {
  const params = useParams();
  const {data: workflow, error, mutate: mutateWorkflow} = useSWR(
    `/intel/flows/${params.id}`,
    getFetcher
  );

  if (!workflow) return;

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="px-10">
        <PaywallList workflow={workflow} />

        <div className="py-6">
          <Heading size="4" mb="2">Triggers</Heading>
          {workflow?.triggers && workflow.triggers.length > 0 ? (
            <div className="">
              {workflow.triggers.map((trigger, index) => (
                <>
                  <TriggerCard key={index} trigger={trigger} index={index} />
                  {index < workflow.triggers.length - 1 && <div className="mb-2 font-bold text-sm pl-11 text-gray-900">OR</div>}
                </>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
              <CloudLightningIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No triggers defined</h3>
              <p className="mt-1 text-sm text-gray-500">
                Add triggers to determine when this workflow should be activated.
              </p>
            </div>
          )}
        </div>

        {/* <div className="space-y-3">
          <Heading size="4" mb="2">Rollouts</Heading>
          <Table.Root variant="surface">
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Client</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Traffic</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Participants</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>&nbsp;</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {workflow.rollouts?.map((rollout, index) => (
                <RolloutItem key={index} rollout={rollout} workflow={workflow} mutate={mutateWorkflow}/>
              ))}
            </Table.Body>
          </Table.Root>
        </div> */}
      </div>
    </WorkflowLayout>
  );
}



function EmptyState({ title, description, actionText, onAction }) {
  return (
    <div className="text-center py-16 rounded-lg">
      <ShoppingCartIcon className="mx-auto h-12 w-12 text-gray-700" />
      <h3 className="mt-4 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-sm text-gray-800 max-w-sm mx-auto">
        {description}
      </p>
      {actionText && (
        <div className="mt-6">
          <Button size="2" onClick={onAction}>
            <PlusIcon className="h-4 w-4 mr-2" />
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
}

export function PaywallList({ workflow }) {


  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const client = params.get('client');

    return {
      client: client || '',
    };
  });

  // Build query string for stats
  const queryString = useMemo(() => {
    const params = new URLSearchParams({
      ...(activeFilters.client && { client: activeFilters.client }),  
    });
    
    // Add timezone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz) params.set('tz', tz);
    
    return params.toString();
  }, [activeFilters]);

  const {
    data: collection,
    error
  } = useSWR(
    `/intel/flows/${workflow.id}/scenarios?${queryString}`, 
    getFetcher,
  );

    // Listen for URL changes
    useEffect(() => {
      const params = new URLSearchParams(location.search);
    
      const client = params.get('client');
      
      if (client) {
        setActiveFilters(current => ({
          ...current,
          client: client || current.client,
        }));
      }
    }, [location.search]);

  if (error) return <div>Failed to load</div>;
  if (!collection) return <div>Loading...</div>;

  return (
    <div className="">
      <div className="flex  gap-2 items-center pt-2 mb-2">
        <Heading as="h1" size="4">Paywall Variants</Heading>
        <Button size="1" variant="" asChild>
          <Link to={`/convert/workflows/${workflow.id}/paywalls/create`}>
            <PlusIcon className="h-4 w-4" />
            Create New 
          </Link>
        </Button>
      </div>

      {collection.data.length === 0 ? (
        <EmptyState
          title="No Paywalls Yet"
          description="Create your first paywall to start monetizing your workflow. Paywalls help you control access to premium content or features."
          actionText="Create Your First Paywall"
          onAction={() => {
            // Navigate to paywall creation page
            window.location.href = `/convert/workflows/${workflow.id}/paywalls/create`;
          }}
        />
      ) : (
        <>
          <div className="grid grid-cols-11 font-bold text-sm bg-gray-50 rounded-lg border rounded-b-none py-3">
            <div className="col-span-3 pl-6">
              Paywall
            </div>
            <div className="col-span-2">
              Unique Views
            </div>
            <div className="col-span-2">
              Conversions
            </div>
            <div className="col-span-2">
              Total Revenue
            </div>
            <div className="col-span-2">
              Actions
            </div>
          </div>

          <div className="space-y-4">
            {collection.data.map(paywall => (
              <PaywallStatsComponent
                key={paywall.id}
                paywall={paywall}
                workflow={workflow}
                isWinner={paywall.id === collection.winner}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}



function PaywallStatsComponent({ analytics, paywall, isWinner, workflow }) {



  return (
    <div className="border border-gray-200/50 border-t-0 overflow-hidden ">
      <div className="grid grid-cols-11">
        
        <div className="px-5 py-2 col-span-3">
            <h3 className="text-lg font-semibold">{paywall.display_name}</h3>
          
              <Link to={`/convert/paywalls/${paywall.id}/view`} className="text-link flex items-center">
                  {/* <ViewIcon className="w-3 h-3 mr-1" />  */}
                  View
                <ArrowRightIcon className="w-3 h-3 ml-1" />
              </Link>
      
        </div>


        <StatColumn
          // icon={<MousePointerClickIcon className="w-3 h-3 flex-shrink-0 " />}
          title="Unique Views"
          value={paywall.stats.unique_views.value}
          change={paywall.stats.unique_views.diff_relative}
          percentage={paywall.stats.unique_views.diff_percentage}
        />

        <StatColumn
          // icon={<CheckCircle className="w-3 h-3 flex-shrink-0 " />}
          title="Conversions"
          value={paywall.stats.conversions_count.value}
          change={paywall.stats.conversions_count.diff_relative}
          percentage={paywall.stats.conversions_count.diff_percentage}
        />

        <StatColumn
          // icon={<CircleDollarSignIcon className="w-3 h-3 flex-shrink-0 " />}
          title="Total Revenue"
          value={paywall.stats.revenue_sum.value}
          change={null}
          percentage={null}
        />

        <div className="flex gap-2 items-start py-6 col-span-2">
          <Button size="1" variant="surface" asChild>
                <Link to={`/convert/paywalls/${paywall.id}/edit`}>
                  <EditIcon className="w-3 h-3 mr-1" /> Edit
                </Link>
              </Button>
              <Button size="1" variant="surface" asChild>
                <Link to={`/convert/paywalls/${paywall.id}/sandbox`}>
                  <TestTubeIcon className="w-3 h-3 mr-1" /> Sandbox
                </Link>
              </Button>
        </div>
      </div>
    </div>
  );
}

function StatColumn({ icon, title, value, change, percentage }) {
  const isPositive = percentage > 0;
  const isNegative = percentage < 0;

  return (
    <div className="space-y-X2 col-span-2 flex flex-col justify-center">
      <div>
        <dd className="mt-1 text-3xl font-semibold ">{value}</dd>
        {/* <dt className="text-sm font-medium flex items-center gap-2 text-sm">
          {icon}
          {title}
        </dt> */}
      </div>
      {percentage === null && <div className="text-gray-700 text-xs">All Time</div>}
      {percentage !== null && (
        <div className="flex items-center text-xs gap-2 ">
        {percentage !== 0 && (
          <div
            className={`flex items-center ${
              isPositive ? 'text-green-600' : isNegative ? 'text-red-600' : 'text-gray-600'
            }`}
          >
            {isPositive && <ArrowUpRightIcon className="w-3 h-3 mr-1" />}
            {isNegative && <ArrowDownRightIcon className="w-3 h-3 mr-1" />}
            {percentage !== 0 ? `${Math.abs(percentage)}%` : ''}
          </div>
        )}

        <div className="text-gray-700">
          {change > 0 ? '+' : ''}{change} this week
        </div>
      </div>
      )}
      
    </div>
  );
}

function TriggerCard({ trigger, index }) {
  const getTriggerIcon = (listen) => {
    switch (listen) {
      case 'attachment':
        return <TextCursorIcon className="h-5 w-5 text-blue-500" />;
      case 'custom':
        return <CodeIcon className="h-5 w-5 text-purple-500" />;
      default:
        return <CloudLightning className="h-5 w-5 text-yellow-500" />;
    }
  };

  return (
    <Card className="mb-2">
      <Flex align="center" gap="3" className="">
        {getTriggerIcon(trigger.listen)}
        <Text weight="bold">Trigger {index + 1}</Text>
        <div className="flex gap-2 flex-wrap">
          <Badge color="blue"><Text>{listenMap[trigger.listen] || trigger.listen}</Text></Badge>
          
          {trigger.qualifier && (
              <Badge color="green"><Text>{qualifierMap[trigger.qualifier] || trigger.qualifier}</Text></Badge>
          )}
          {trigger.props && Object.keys(trigger.props).length > 0 && (
              <Badge color="purple"><Text>{Object.entries(trigger.props).map(([key, value]) => `${key}: "${value}"`).join(', ')}</Text></Badge>
          )}
          {trigger.event && (
              <Badge color="orange"><Text>{eventMap[trigger.event] || trigger.event}</Text></Badge> 
          )}
        </div>
      </Flex>
    </Card>
  );
}

function RolloutItem({rollout, workflow, mutate}) {
  const [data, setData] = React.useState({
    rules: rollout.rules,
    is_active: rollout.is_active,
  });
  const [errors, setErrors] = React.useState(null);
  const closeButton = useRef();

  function handleRollout(evt) {
    evt.preventDefault();
    console.log('save', data);
    apiClient.put(`/convert/workflows/${workflow.id}/rollouts/${rollout.id}`, data)
      .then(() => {
        mutate();
        closeButton.current.click();
      })
      .catch(err => {
        setErrors(err.response.data || [{message: 'An error occurred'}]);
      })
  }

  return (
    <Table.Row>
      <Table.Cell><Link to={`/settings/clients/${rollout.client.id}`}>{rollout.client.name}</Link> </Table.Cell>
      <Table.Cell>
        {rollout.percentage}% of traffic
      </Table.Cell>
      <Table.Cell>
        {rollout.is_active && (
          <span className="text-green-500 font-semibold text-sm"><i className="fa fa-play  text-sm"/> live</span>
        )}
        {!rollout.is_active && (
          <span className="text-amber-500 font-semibold text-sm"><i className="fa fa-pause text-amber-400"/> paused </span>
        )}
      </Table.Cell>
      <Table.Cell>
        {rollout.participations_count}
      </Table.Cell>
      <Table.Cell>
        <Dialog.Root>
          <Dialog.Trigger>
            <Button variant="ghost">Edit</Button>
          </Dialog.Trigger>

          <Dialog.Content maxWidth="450px">
            <Dialog.Title>Edit Rollout</Dialog.Title>
            <Dialog.Description size="2" mb="4">Make changes to this rollout</Dialog.Description>

            <Form.Root onSubmit={handleRollout}>
              <Flex direction="column" gap="3">
                <label>
                  <Text as="div" size="2" mb="1" weight="bold">Active</Text>
                  <Switch checked={data.is_active} onCheckedChange={c => setData({...data, is_active: c})}/>
                  {errors && errors.is_active && <Text size="1" color="red">{errors.is_active[0]}</Text>}
                </label>
                {data?.rules?.map((rule, index) => (
                  <Flex direction="column" gap="3" key={index}>
                    {rule.type === 'percentage' && (
                      <label>
                        <Text as="div" size="2" mb="1" weight="bold">Percentage</Text>
                        <TextInput
                          type="number"
                          min={0}
                          max={100}
                          value={rule.value}
                          name="value"
                          onChange={value => setData(update(data, {rules: {[index]: {value: {$set: Number(value)}}}}))}
                          placeholder="Percentage"
                        />
                      </label>
                    )}
                    {errors && errors.rules && errors.rules[index] && (
                      <Text size="1" color="red">{errors.rules[index].value}</Text>)}
                  </Flex>
                ))}
              </Flex>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray" type="button" ref={closeButton}>Cancel</Button>
                </Dialog.Close>
                <Button>Save</Button>
              </Flex>
            </Form.Root>
          </Dialog.Content>
        </Dialog.Root>
      </Table.Cell>
    </Table.Row>
  )
}
