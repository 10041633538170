import React from 'react';
import { Handler } from '~/types';
import TextInput from '~/components/form/TextInput';
import SelectInput from '~/components/form/SelectInput';

interface HandlerFormProps {
  handler: Handler;
  onChange: (handler: Handler) => void;
  onDelete: () => void;
  errors?: Record<string, [string]>;
}

const LISTEN_OPTIONS = [
  { id: 'attachment', name: 'Element Attachment' },
  { id: 'custom', name: 'Custom Event' }
];

const QUALIFIER_OPTIONS = [
  { id: 'findButtonByText', name: 'Button with Text' },
  // Add more qualifiers as needed
];

const EVENT_OPTIONS = [
  { id: 'click', name: 'Click' },
  // Add more events as needed
];

export default function HandlerForm({ handler, onChange, onDelete, errors }: HandlerFormProps) {
  const isCustomEvent = handler.listen === 'custom';

  return (
    <div className="bg-white rounded-lg border border-gray-700 p-3">
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 flex-1">
            <SelectInput
              value={handler.listen}
              onValueChange={(value) => onChange({ ...handler, listen: value as 'attachment' | 'custom' })}
              options={LISTEN_OPTIONS}
              placeholder="Select type"
            />

            {isCustomEvent ? (
              <div className="flex-1">
                <TextInput
                  value={handler.event_name}
                  onChange={(value) => onChange({ ...handler, event_name: value })}
                  placeholder="my.custom.event"
                  errors={errors?.event_name}
                />
              </div>
            ) : (
              <div className="flex gap-3 flex-1">
                <SelectInput
                  value={handler.qualifier || ''}
                  onValueChange={(value) => onChange({ ...handler, qualifier: value })}
                  options={QUALIFIER_OPTIONS}
                  placeholder="Select qualifier"
                />

                <SelectInput
                  value={handler.event_name}
                  onValueChange={(value) => onChange({ ...handler, event_name: value })}
                  options={EVENT_OPTIONS}
                  placeholder="Select event"
                />

                {handler.qualifier === 'findButtonByText' && (
                  <div className="flex-1">
                    <TextInput
                      value={handler.props?.text || ''}
                      onChange={(value) => onChange({ 
                        ...handler, 
                        props: { ...handler.props, text: value } 
                      })}
                      placeholder="Button text..."
                      errors={errors?.['props.text']}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <button 
            onClick={onDelete}
            type="button"
            className="ml-2 p-1 text-sm text-gray-700 hover:text-red-700"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>

        {/* Show errors in a compact way */}
        {errors && Object.entries(errors).map(([key, [error]]) => {
          // Don't show errors that are already shown in TextInput components
          if (key === 'event_name' || key === 'props.text') return null;
          return (
            <p key={key} className="text-xs text-red-700 mt-1">
              {error}
            </p>
          );
        })}
      </div>
    </div>
  );
} 