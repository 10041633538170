import { useParams } from "react-router-dom";
import WorkflowLayout from "./WorkflowLayout";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Table } from "@radix-ui/themes";
import Pagination from "~/components/Pagination"; // Assuming you have a Pagination component
import { useState } from "react";

export default function WorkflowEventsPage() {
const params = useParams();
const [page, setPage] = useState(1);

const { data: workflow, error: workflowError, mutate: mutateWorkflow } = useSWR(`/intel/flows/${params.id}`, getFetcher);

const { data: eventsData, error: eventsError, isLoading: eventsLoading } = useSWR(
    `/convert/workflows/${params.id}/events?page=${page}`,
    getFetcher
);

if (workflowError || eventsError) return <div>Error</div>;
if (!workflow || !eventsData) return <div></div>;

if (eventsLoading || !eventsData) return <div>Loading...</div>;

return (
    <WorkflowLayout workflow={workflow}>
        <div className="space-y-4 px-10">
        <Table.Root variant="surface">
            <Table.Header>
                <Table.Row>
                    <Table.ColumnHeaderCell>Event Name</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Collector ID</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Data</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Occurred At</Table.ColumnHeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {eventsData?.data?.map((event) => (
                    <Table.Row key={event.id}>
                        <Table.Cell>{event.type}</Table.Cell>
                        <Table.Cell>{event.collector_id}</Table.Cell>
                        <Table.Cell>{JSON.stringify(event.data, null, 2)}</Table.Cell>
                        <Table.Cell>{event.created_at}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table.Root>
        <Pagination
            resource={eventsData}
            onPageChange={setPage}
        />
        </div>
    </WorkflowLayout>
);
}