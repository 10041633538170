import { Heading, Table } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import Pagination from "~/components/Pagination";
import { Link, useSearchParams } from "react-router-dom";

interface ActivityAction {
  id: string;
  event_name: string;
  type: string;
  datetime: string;
  properties: Record<string, any>;
  metadata: Record<string, any>;
}

interface Activity {
  id: string;
  current_state: string;
  customer_id?: string;
  customer_email?: string;
  customer_created_at?: number;
  country_flag: string;
  country_code: string;
  country_name: string;
  os_code?: string;
  os_name?: string;
  browser_code?: string;
  browser_name?: string;
  actions: ActivityAction[];
  handler?: {
    id: string;
    event_name: string;
  };
  flow?: {
    id: string;
    display_name: string;
  };
}

interface PaginationMeta {
  current_page: number;
  from: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface ActivityResponse {
  data: Activity[];
  meta: PaginationMeta;
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
}

export default function ActivitiesListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  const flow = searchParams.get('flow');
  
  const { data, error, isLoading } = useSWR<ActivityResponse>(
    flow ? `/intel/flows/${flow}/activities?page=${currentPage}` : null,
    getFetcher
  );

  const handlePageChange = (page: number) => {
    setSearchParams({ flow, page: page.toString() });
  };

  if (!flow) {
    return (
      <div className="p-10">
        <div>No flow ID provided</div>
      </div>
    );
  }

  return (
    <div className="p-10 space-y-4">
      <div className="flex items-center justify-between">
        <Heading size="6">Activities</Heading>
      </div>

      <div>
        {error && <div>Failed to load</div>}
        {(!data && !error) && <div>Loading...</div>}
        {data && (
          <>
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell className="w-[20%]">Activity</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell className="w-[20%]">Flow Details</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell className="w-[20%]">Environment</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell className="w-[40%]">Actions</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data.map((activity) => (
                  <Table.Row key={activity.id}>
                    <Table.Cell>
                      <div className="space-y-1">
                        <div className="text-sm">{activity.id}</div>
                        {activity.customer_id && (
                          <Link className="text-link block" to={`/customers/${activity.customer_id}`}>
                            {activity.customer_email || activity.customer_id}
                          </Link>
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="space-y-1">
                        <div>{activity.flow?.display_name}</div>
                        <div className="text-sm">{activity.current_state}</div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="space-y-1">
                        <div>{activity.country_flag} {activity.country_name}</div>
                        <div className="text-sm">
                          {activity.os_name && activity.browser_name ? 
                            `${activity.os_name} / ${activity.browser_name}` : 
                            'Unknown'}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="space-y-3">
                        {activity.actions?.map((action, index) => (
                          <div key={action.id} className={`${index > 0 ? 'border-t pt-3' : ''}`}>
                            <div className="flex items-center gap-2 mb-1">
                              <span className="bg-blue-100 text-blue-800 text-xs px-2.5 py-0.5 rounded-full font-medium">
                                {action.type}
                              </span>
                              <span className="text-xs">
                                {new Date(action.datetime).toLocaleString()}
                              </span>
                            </div>
                            <div className="font-medium">{action.event_name}</div>
                            <div className="text-xs space-y-1 mt-1">
                              <div>ID: {action.id}</div>
                              {Object.keys(action.properties || {}).length > 0 && (
                                <div className="mt-2">
                                  <div className="text-sm font-medium mb-1">Properties</div>
                                  <pre className="bg-gray-50 p-2 rounded">
                                    {JSON.stringify(action.properties, null, 2)}
                                  </pre>
                                </div>
                              )}
                              {Object.keys(action.metadata || {}).length > 0 && (
                                <div className="mt-2">
                                  <div className="text-sm font-medium mb-1">Metadata</div>
                                  <pre className="bg-gray-50 p-2 rounded">
                                    {JSON.stringify(action.metadata, null, 2)}
                                  </pre>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>

            <Pagination
              resource={data}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
}
