import React, { useState, useEffect, useRef } from 'react';
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {Table, Button, Switch, Badge} from "@radix-ui/themes";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { mutate } from 'swr';
import * as Toast from '@radix-ui/react-toast';
import cx from 'classnames';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/components/Dialog";

interface ActivityAction {
  id: string;
  event_name: string;
  type: string;
  datetime: string;
  properties: Record<string, any>;
  metadata: Record<string, any>;
}

interface ActivitySession {
  id: string;
  user_name: string;
  customer_id?: string;
  customer_email?: string;
  country_flag: string;
  placement: string;
  variant_name: string;
  total_views_count: number;
  last_interaction_at: number;
  latest_event: string;
  is_excluded: boolean;
  actions: ActivityAction[];
  country_name: string;
  browser_name?: string;
  os_name?: string;
}

interface ActivityEvent {
  type: string;
  created_at: number;
}

interface ActivityDetails {
  events: ActivityEvent[];
}

interface ActivityCollection {
  data: ActivitySession[];
  meta: {
    current_page: number;
    per_page: number;
  };
  links: {
    next?: string;
    prev?: string;
  };
}

function exclusionReason(reason: string) {
  switch (reason) {
    case 'manual':
      return 'Manually excluded';
    case 'client_exclusion':
      return 'Client Rule';
    default:
      return 'no reason';
  }
}
function ParticipantList({ workflow }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 10; // Number of items per page

  // Get initial page from URL or default to 1
  const initialPage = new URLSearchParams(location.search).get('page');
  const [page, setPage] = useState(initialPage ? parseInt(initialPage) : 1);
  const [selectedSession, setSelectedSession] = useState<ActivitySession | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {                       
    data: collection,
    error
  } = useSWR<ActivityCollection>(`/intel/flows/${workflow.id}/activities?page=${page}&pageSize=${pageSize}`, getFetcher);
  
  useEffect(() => {
    // Update URL when page changes
    navigate(`${location.pathname}?page=${page}`, { replace: true });
  }, [page, navigate, location.pathname]);

  const [savingId, setSavingId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const [open, setOpen] = useState(false);
  const timerRef = useRef(0);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const toggleExclusion = async (sessionId, currentStatus) => {
    setSavingId(sessionId);
    try {
      await apiClient.patch(`/convert/paywall_sessions/${sessionId}`, {
        is_excluded: !currentStatus
      });
      setToastMessage('Session status updated successfully');
      mutate(`/convert/workflows/${workflow.id}/sessions?page=${page}&pageSize=${pageSize}`);
    } catch (error) {
      setToastMessage('Failed to update session status');
    } finally {
      setSavingId(null);
      setOpen(false);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setOpen(true);
      }, 100);
    }
  };

  function dateFormat(time: number) {
    
    // format date as "time ago" format, the value will be in UTC timestamp
    return formatDistanceToNow(time*1000, { addSuffix: true });
  }

  const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setPage(prev => prev + 1);

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
  };

  return (
    <Toast.Provider swipeDirection="right">
      <div className="px-10">
        <Table.Root variant="surface">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>User Info</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Trigger</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Variant</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Total Views</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Last Interaction</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Latest Event</Table.ColumnHeaderCell>
              {/* <Table.ColumnHeaderCell>Included</Table.ColumnHeaderCell> */}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {collection?.data?.map((session, index) => (
              <Table.Row 
                key={index} 
                className={cx(
                  'cursor-pointer hover:bg-gray-50',
                  {'bg-gray-50 text-gray-800': session.is_excluded}
                )}
                onClick={() => handleRowClick(session)}
              >
                <Table.Cell>
                  <div className="flex items-center gap-2">
                    <div className="w-6">{session.country_flag}</div>
                    {session.customer_id && (
                      <Link to={`/customers/${session.customer_id}`} className="text-sm text-teal-800 font-medium underline" >
                        {session.customer_email || session.user_name}
                      </Link>
                    )}
                    {!session.customer_id && (
                      <div>
                        {session.user_name}
                      </div>
                    )}
                  </div>
                  {/* {session.is_excluded && (
                      <div className="ml-8 text-red-500 text-sm">{exclusionReason(session.exclusion_reason)}</div>
                    )} */}
                </Table.Cell>
                <Table.Cell>
                  {session.placement}
                </Table.Cell>
                <Table.Cell>
                  {session.variant_name}
                </Table.Cell>
                <Table.Cell>
                  {/* {session.customer_created_at ? dateFormat(session.customer_created_at) : '-'} */}
                  {session.total_views_count}
                </Table.Cell>
                <Table.Cell>
                  {session.last_interaction_at ? dateFormat(session.last_interaction_at) : '-'}
                </Table.Cell>
                <Table.Cell>
                  <SessionBadge event={session.latest_event} />
                </Table.Cell>
                {/* <Table.Cell>
                  <Switch
                    checked={!session.is_excluded}
                    onCheckedChange={() => toggleExclusion(session.id, session.is_excluded)}
                    disabled={savingId === session.id}
                  />
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Activity Details</DialogTitle>
            </DialogHeader>
            
            {selectedSession && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">User</h3>
                    <p>{selectedSession.user_name}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">Location</h3>
                    <p>{selectedSession.country_flag} {selectedSession.country_name}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">Browser</h3>
                    <p>{selectedSession.browser_name || 'Unknown'}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">OS</h3>
                    <p>{selectedSession.os_name || 'Unknown'}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">Trigger</h3>
                    <p>{selectedSession.placement}</p>
                  </div>
                  <div>
                    <h3 className="font-medium text-sm text-gray-700">Variant</h3>
                    <p>{selectedSession.variant_name}</p>
                  </div>
                </div>

                <div>
                  <h3 className="font-medium text-sm text-gray-700 mb-2">Activity Timeline</h3>
                  <div className="space-y-2">
                    {selectedSession.actions.map((action, index) => (
                      <div key={index} className="flex items-center gap-2 text-sm">
                        <SessionBadge event={action.type} />
                        <span className="text-gray-700">{formatDistanceToNow(new Date(action.datetime), { addSuffix: true })}</span>
                        {action.event_name && (
                          <span className="text-gray-600">- {action.event_name}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        <div className="mt-4 flex justify-between items-center">
          <Button 
            onClick={handlePrevPage} 
            disabled={page === 1}
          >
            Previous
          </Button>
          <span>Page {page}</span>
          <Button 
            onClick={handleNextPage} 
            disabled={!collection?.data || collection.data.length < pageSize}
          >
            Next
          </Button>
        </div>

        <Toast.Root
          className="bg-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
          open={open}
          onOpenChange={setOpen}
        >
          <Toast.Title className="[grid-area:_title] mb-[5px] font-medium text-slate12 text-[15px]">
            {toastMessage}
          </Toast.Title>
        </Toast.Root>
        <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
      </div>
    </Toast.Provider>
  )
}

const eventNameMap = {
  'start': { name: 'Started', color: 'blue' },
  'entry': { name: 'Entry', color: 'gray' },
  'scene:view': { name: 'Viewed', color: 'gray' },
}

function SessionBadge({ event }) {
  if (event === 'convert') {
    return <Badge color="green">Conversion!</Badge>
  }
  if (event?.substring(0, 6) === 'error:') {
    return <Badge color="red">Error</Badge>
  }
  return <Badge color={eventNameMap[event]?.color || 'gray'}>{eventNameMap[event]?.name || event}</Badge>
}

export default function WorkflowParticipationPage() {

  const params = useParams();

  const {data: workflow, error} = useSWR(
    `/intel/flows/${params.id}`,
    url => apiClient.get(url).then(res => res.data)
  );

  if (!workflow) return;

  return (
    <WorkflowLayout workflow={workflow}>

      <ParticipantList
        workflow={workflow}
      />


    </WorkflowLayout>
  )
}
