import {useParams} from "react-router";
import PageHeader from "~/components/PageHeader";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import Debug from "~/components/Debug";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import {get} from "lodash";
import React, {useEffect, useState} from "react";
import SelectInput, { SelectInputProps } from "~/components/form/SelectInput";
import JSONInput, { JSONInputProps } from "~/components/form/JSONInput";
import ErrorList from "~/components/ErrorList";
import {useNavigate} from "react-router-dom";
import * as Form from "@radix-ui/react-form";
import { PaywallIntent } from "~/types";
import { UpdatePaywallRequest } from "~/types/api/paywall";
import { Button, Checkbox, Heading, Link } from "@radix-ui/themes";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";
import ElementSelect from "~/components/form/ElementSelect";
import type { Element } from "~/components/form/ElementSelect";
import { createElement } from "~/api/elements";
import ElementModal from "~/components/form/ElementModal";
import Alert from "~/components/Alert";

const INTENT_OPTIONS = [
  {id: PaywallIntent.Upgrade, name: 'Upgrade'},
  {id: PaywallIntent.Expansion, name: 'Expansion'},
]

const MODE_OPTIONS = [
  {id: 'payment', name: 'Payment'},
  {id: 'setup', name: 'Setup'},
]

const TYPE_OPTIONS = [
  {id: 'modal', name: 'Modal'},
]

interface SchemesResponse {
  data: Array<{
    id: string;
    name: string;
  }>;
}

interface Workflow {
  id: string;
  display_name: string;
}

interface Scenario {
  display_name: string;
  scheme?: {
    id: string;
  };
  intent: PaywallIntent;
  mode: 'managed';
  type: 'modal';
  id: string;
  element?: Element;
  properties: {
    can_change_interval_on_expansion: boolean;
  };
  conditions: any[];
  purchasables: [{}];
  flow: Workflow;
}

export default function PaywallEditPage() {
  const { paywallId } = useParams();
  const navigate = useNavigate();

  const [selectedElement, setSelectedElement] = useState<Element | null>(null);
  const [isCreatingElement, setIsCreatingElement] = useState(false);
  const [isElementModalOpen, setIsElementModalOpen] = useState(false);
  const [elementToDuplicate, setElementToDuplicate] = useState<Element | null>(null);
  const [elementToEdit, setElementToEdit] = useState<Element | null>(null);

  const {data: paywall, error: paywallError} = useSWR<Scenario>(`/intel/scenarios/${paywallId}`, getFetcher);
  const {data: schemes, error: schemesError} = useSWR<SchemesResponse>(`/pricing/schemes`, getFetcher);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<UpdatePaywallRequest>();

  useEffect(() => {
    if (paywall) {
      setData({
        display_name: paywall.display_name,
        scheme_id: paywall.scheme?.id || get(schemes, 'data.0.id'),
        intent: paywall.intent,
        type: paywall.type,
        lookup_key: paywall.id,
        element_id: paywall.element?.id,
        properties: {
          can_change_interval_on_expansion: get(paywall, 'properties.can_change_interval_on_expansion', false)
        },
        conditions: paywall.conditions || [],
        purchasables: paywall.purchasables || [{
          id: '<line_item_1>',
          object: 'plan',
        }] as [{}]
      });
      if (paywall.element) {
        setSelectedElement(paywall.element);
      }
    }
  }, [paywall]);

  async function handleElementCreate() {
    if (!data.element) return;
    
    setIsCreatingElement(true);
    try {
      const element = await createElement({
        display_name: `${data.display_name} Element`,
        mode: 'managed' as const,
        type: 'paywall_modal' as const,
        view: JSON.parse(data.element.view),
        template: JSON.parse(data.element.template),
        lookup_key: data.lookup_key
      });
      setSelectedElement(element);
      setData({
        ...data,
        element_id: element.id
      });
      toast.success("Element created successfully");
    } catch (error) {
      console.error("Failed to create element:", error);
      toast.error("Failed to create element");
    } finally {
      setIsCreatingElement(false);
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors([]);
    setIsLoading(true);

    // Create element first if it hasn't been created
    if (!selectedElement && data.element) {
      handleElementCreate().then(() => {
        submitPaywall();
      });
      return;
    }

    submitPaywall();
  }

  function submitPaywall() {
    const payloadData = {
      ...data,
      element_id: selectedElement?.id
    };
    console.log(payloadData);
    delete payloadData.element;

    apiClient.put(`/intel/scenarios/${paywallId}`, payloadData).then(res => {
      toast.success("Scenario updated");
      navigate(`/convert/workflows/${paywall.flow.id}/paywalls`);
    }).catch(err => {
      setIsLoading(false);
      setErrors(err.response.data.errors || [err.response.data.message]);
    });
  }

  function textField(id: string, label: string): TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onChange: (value) => setData({...data, [id]: value}),
    }
  }

  function jsonField(id: string, label: string): JSONInputProps {
    const value = get(data, id);
    return {
      id,
      label,
      value: typeof value === 'string' ? JSON.parse(value) : value,
      errors: get(errors, id),
      onChange: (value) => {
        setData({...data, [id]: value});
      },
    }
  }

  function selectField(id: string, label: string, options: any[]): SelectInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onValueChange: (value) => setData({...data, [id]: value}),
      options,
    }
  }

  function handleElementCreated(element: Element) {
    setSelectedElement(element);
    setData({
      ...data,
      element_id: element.id
    });
  }

  if (!paywall) return <div>Loading...</div>;
  if (!data) return <div>Loading...</div>;
  if (paywallError) return <div>Error loading scenario</div>;

  return (
    <div className="p-10">
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert/workflows/">Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${paywall.flow.id}`}>{paywall.flow.display_name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbPage>Edit Scenario</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeader>
        <Heading>Edit Scenario</Heading>
      </PageHeader>

      <Form.Root onSubmit={handleSubmit} className="space-y-2">
        <TextInput {...textField('display_name', 'Name')}/>
        
        <TextInput {...textField('lookup_key', 'Lookup Key')}/>

        <SelectInput {...selectField('scheme_id', 'Pricing Scheme', get(schemes, 'data', []))}/>
        {schemes?.data.length === 0 && (
          <Alert title="No Offerings">No offerings, please create one <Link href={`/catalog/pricing`}>here</Link></Alert>
        )}

        <SelectInput {...selectField('intent', 'Intent', INTENT_OPTIONS)}/>
        {data?.intent === PaywallIntent.Expansion  && (
          <div className="text-xs flex space-x-2.5 mt-1">
            <span>Can select and change interval?</span>
            <Checkbox variant="soft" checked={data.properties?.can_change_interval_on_expansion} onCheckedChange={(state) => setData({ ...data, properties: { ...data.properties, can_change_interval_on_expansion: state as boolean }}) } />
          </div>
        )}

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <Heading size="3">Element</Heading>
            <div className="space-x-2">
              <Button 
                type="button" 
                onClick={() => {
                  setElementToDuplicate(null);
                  setIsElementModalOpen(true);
                }}
              >
                Create New Element
              </Button>
            </div>
          </div>

          <ElementSelect
            value={data.element_id}
            onChange={(element) => {
              setSelectedElement(element);
              setData({
                ...data,
                element_id: element.id
              });
            }}
            label="Select Element"
          />
          
          {selectedElement && (
            <div className="flex items-center space-x-2">
              <Button 
                type="button" 
                variant="soft"
                onClick={() => {
                  setElementToDuplicate(null);
                  setIsElementModalOpen(true);
                  setElementToEdit(selectedElement);
                }}
              >
                Edit Element
              </Button>
              <Button 
                type="button" 
                variant="soft"
                onClick={() => {
                  setElementToDuplicate(selectedElement);
                  setElementToEdit(null);
                  setIsElementModalOpen(true);
                }}
              >
                Duplicate Selected Element
              </Button>
            </div>
          )}
          {/* <pre>{JSON.stringify(selectedElement, null, 2)}</pre> */}
        </div>

        <JSONInput {...jsonField('conditions', 'Conditions')}/>
        <JSONInput {...jsonField('purchasables', 'Purchasables')}/>

        <ErrorList errors={errors}/>

        <div className="flex items-center justify-between">
          <Button loading={isLoading} type="submit">Save Changes</Button>
        </div>
      </Form.Root>

      <ElementModal 
        isOpen={isElementModalOpen}
        onClose={() => {
          setIsElementModalOpen(false);
          setElementToDuplicate(null);
          setElementToEdit(null);
        }}
        onSuccess={handleElementCreated}
        sourceElement={elementToDuplicate}
        elementToEdit={elementToEdit}
      />

      <Debug {...data}/>
    </div>
  );
}