import { useState, useEffect } from 'react';
import { Button } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import JSONInput from './JSONInput';
import { Element } from './ElementSelect';
import { createElement, updateElement, fetchElement, ELEMENTS_KEY } from '~/api/elements';
import { toast } from 'react-toastify';
import TextInput from './TextInput';
import { get } from 'lodash';
import bonjoroStages from '~/stage-json/bonjoro-stages';
import useSWR from 'swr';

type ElementModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (element: Element) => void;
  sourceElement?: Element;
  elementToEdit?: Element;
};

type ElementFormData = {
  display_name: string;
  lookup_key: string;
  type: 'paywall_modal';
  mode: 'managed';
  view: object;
  template: object;
};

export default function ElementModal({ isOpen, onClose, onSuccess, sourceElement, elementToEdit }: ElementModalProps) {
  const { data: fullElementData } = useSWR(
    elementToEdit ? `${ELEMENTS_KEY}/${elementToEdit.id}` : null,
    elementToEdit ? () => fetchElement(elementToEdit.id) : null
  );

  const [data, setData] = useState<ElementFormData>({
    display_name: '',
    lookup_key: '',
    view: {},
    type: 'paywall_modal',
    mode: 'managed',
    template: {}
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [isLookupKeyLocked, setIsLookupKeyLocked] = useState(true);

  // Reset form when modal opens or element changes
  useEffect(() => {
    if (!isOpen) return;

    if (elementToEdit && fullElementData) {
      console.log('Setting edit data:', JSON.stringify(fullElementData, null, 2));
      setData({
        display_name: fullElementData.display_name,
        lookup_key: fullElementData.id,

        view: typeof fullElementData.view === 'string' ? JSON.parse(fullElementData.view) : fullElementData.view,
        template: typeof fullElementData.template === 'string' ? JSON.parse(fullElementData.template) : fullElementData.template,
        
        type: 'paywall_modal',
        mode: 'managed'
      });
    } else if (sourceElement) {
      // Fetch the full source element data when duplicating
      fetchElement(sourceElement.id).then(fullSourceElement => {
        console.log('Setting duplicate data:', JSON.stringify(fullSourceElement, null, 2));
        setData({
          display_name: `${fullSourceElement.display_name} Copy`,
          lookup_key: '',
          view: typeof fullSourceElement.view === 'string' ? JSON.parse(fullSourceElement.view) : fullSourceElement.view,
          template: typeof fullSourceElement.template === 'string' ? JSON.parse(fullSourceElement.template) : fullSourceElement.template,
          type: 'paywall_modal',
          mode: 'managed'
        });
      });
    } else {
      console.log('Setting new data');
      setData({
        display_name: '',
        lookup_key: '',
        view: {},
        type: 'paywall_modal',
        mode: 'managed',
        template: {}
      });
    }
    setErrors({});
  }, [isOpen, elementToEdit, sourceElement, fullElementData]);

  // Don't show modal if we're editing and don't have the full data yet
  if (!isOpen || (elementToEdit && !fullElementData)) return null;

  // Don't show modal if we're not creating new, editing (with data), or duplicating
  if (!elementToEdit && !sourceElement && !isOpen) return null;

  console.log('ElementModal render:', JSON.stringify({
    elementToEdit,
    sourceElement,
    fullElementData,
    data
  }, null, 2));

  function textField(id: 'display_name' | 'lookup_key', label: string) {
    const value = data[id];
    return {
      name: id,
      label,
      value: value || '',
      errors: get(errors, id)?.length ? [get(errors, id)[0]] as [string] : null,
      onChange: (value: string) => setData(prev => ({ ...prev, [id]: value }))
    };
  }

  function jsonField(id: 'view' | 'template', label: string) {
    const value = data[id];
    console.log(`JSON field ${id}:`, JSON.stringify({ raw: value, type: typeof value }, null, 2));
    return {
      id,
      label,
      value: typeof value === 'string' ? JSON.parse(value) : value || {},
      errors: get(errors, id),
      onChange: (value: object) => setData(prev => ({ ...prev, [id]: value }))
    };
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
      const payload = {
        display_name: data.display_name,
        lookup_key: data.lookup_key,
        view: data.view,
        template: data.template || {},
        type: data.type,
        mode: data.mode
      };

      let element;
      if (elementToEdit) {
        element = await updateElement(elementToEdit.id, payload);
        toast.success("Element updated successfully");
      } else {
        element = await createElement(payload);
        toast.success(sourceElement ? "Element duplicated successfully" : "Element created successfully");
      }
      
      onSuccess(element);
      onClose();
    } catch (error) {
      console.error("Failed to create/update element:", error);
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      } else {
        toast.error(elementToEdit ? "Failed to update element" : "Failed to create element");
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />
      <div className="fixed left-[50%] top-[50%] max-h-[95vh] w-[95vw] max-w-[1400px] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white p-6 shadow-lg overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">
            {elementToEdit ? 'Edit Element' : sourceElement ? 'Duplicate Element' : 'Create New Element'}
          </h2>
          <Button variant="soft" color="gray" onClick={onClose}>×</Button>
        </div>

        <Form.Root onSubmit={handleSubmit} className="space-y-6">
          <TextInput
            {...textField('display_name', 'Element Name')}
            placeholder="Enter a descriptive name for this element"
            required
          />

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <TextInput
                {...textField('lookup_key', 'Lookup Key')}
                placeholder="Enter a unique lookup key for this element"
                required
                disabled={elementToEdit && isLookupKeyLocked}
                className="flex-1"
              />
              {elementToEdit && (
                <Button 
                  type="button"
                  variant="soft"
                  color={isLookupKeyLocked ? "gray" : "red"}
                  onClick={() => {
                    if (isLookupKeyLocked) {
                      const confirm = window.confirm(
                        "Warning: Changing the lookup key can break existing references to this element. " +
                        "Only change this if you're sure you know what you're doing."
                      );
                      if (confirm) {
                        setIsLookupKeyLocked(false);
                      }
                    } else {
                      setIsLookupKeyLocked(true);
                      setData(prev => ({ ...prev, lookup_key: elementToEdit.id }));
                    }
                  }}
                  className="ml-2"
                >
                  {isLookupKeyLocked ? "🔒 Unlock" : "🔓 Lock"}
                </Button>
              )}
            </div>
            {elementToEdit && !isLookupKeyLocked && (
              <div className="text-amber-600 text-xs">
                ⚠️ Warning: Changing the lookup key can break existing references to this element
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-6">
            <Form.Field name="view">
              <div className="flex justify-between items-center mb-2">
                <Form.Label className="block text-sm font-medium">View Configuration</Form.Label>
                <Button 
                  type="button" 
                  size="1"
                  variant="soft" 
                  onClick={() => {

                    setData({
                      ...data,
                      view: bonjoroStages
                    });
                  }}
                >
                  Use Default Template
                </Button>
              </div>
              <JSONInput
                {...jsonField('view', '')}
                rows={20}
              />
            </Form.Field>

            <Form.Field name="template">
              <Form.Label className="block text-sm font-medium mb-2">Template Configuration</Form.Label>
              <JSONInput
                {...jsonField('template', '')}
                rows={20}
              />
            </Form.Field>
          </div>

          {errors.json && (
            <div className="text-sm text-rose-600">
              {errors.json[0]}
            </div>
          )}

          {/* Validation Errors Summary */}
          {Object.keys(errors).length > 0 && (
            <div className="rounded-md bg-rose-50 p-4 mt-4">
              <div className="text-sm text-rose-700 font-medium mb-2">Please correct the following errors:</div>
              <ul className="list-disc pl-5 text-sm text-rose-600">
                {Object.entries(errors).map(([field, messages]) => (
                  messages.map((message, i) => (
                    <li key={`${field}-${i}`}>{message}</li>
                  ))
                ))}
              </ul>
            </div>
          )}

          <div className="flex justify-end space-x-2 mt-6">
            <Button 
              type="button" 
              variant="soft" 
              onClick={onClose}
            >
              Cancel
            </Button>
            <Form.Submit asChild>
              <Button 
                loading={isLoading}
              >
                {elementToEdit ? 'Save Changes' : sourceElement ? 'Duplicate Element' : 'Create Element'}
              </Button>
            </Form.Submit>
          </div>
        </Form.Root>
      </div>
    </div>
  );
} 