import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Form} from "@radix-ui/react-form";
import TextInput from "~/components/form/TextInput";
import Alert from "~/components/Alert";
import { Workflow, Handler } from "~/types";
import { WorkflowCreateRequest } from "~/types/api/workflow";
import { usePlandalf } from "@plandalf/react-plandalf-js";
import PageHeader from "~/components/PageHeader";
import HandlerForm from "~/components/workflow/HandlerForm";

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

const DEFAULT_HANDLER: Handler = {
  listen: 'attachment',
  event: 'click',
  qualifier: 'findButtonByText',
  props: {
    text: '',
  },
};

const DEFAULT_STATE: WorkflowCreateRequest = {
  name: 'Paywall Workflow',
  lookup_key: '',
  feature_id: null,
  handlers: [],
  clients: [],
  images: []
}

export default function WorkflowCreatePage() {
  const {data: clients, error: clientsError} = useSWR(`/clients`, getFetcher<{data: Workflow['clients']}>);
  const [errors, setErrors] = useState<Record<string, [string]> | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { plandalf } = usePlandalf();
  const [data, setData] = useState<WorkflowCreateRequest>(DEFAULT_STATE);
  const [showPreview, setShowPreview] = useState(false);

  const handleHandlerChange = (index: number, handler: Handler) => {
    const newHandlers = [...data.handlers];
    newHandlers[index] = handler;
    setData({ ...data, handlers: newHandlers });
  };

  const handleHandlerDelete = (index: number) => {
    const newHandlers = [...data.handlers];
    newHandlers.splice(index, 1);
    setData({ ...data, handlers: newHandlers });
  };

  const addHandler = () => {
    setData({ ...data, handlers: [...data.handlers, { ...DEFAULT_HANDLER }] });
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrors(null);
    setIsSaving(true);

    apiClient.post(`/convert/workflows`, data).then(res => {
      navigate(`/convert/workflows/${res.data.id}`);
    }).catch(err => {
      setIsSaving(false);
      if (err.response?.status === 422) {
        const responseErrors = err.response.data.errors;
        // Convert error arrays to single-element arrays and flatten nested errors
        const formattedErrors: Record<string, [string]> = {};
        Object.entries(responseErrors).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            formattedErrors[key] = [value[0]];
          } else if (typeof value === 'object') {
            // Handle nested errors (e.g., handlers.0.event)
            Object.entries(value as Record<string, string[]>).forEach(([nestedKey, nestedValue]) => {
              formattedErrors[`${key}.${nestedKey}`] = [nestedValue[0]];
            });
          }
        });
        setErrors(formattedErrors);
      } else {
        setErrors({ general: [err.response?.data?.message || 'An error occurred'] });
      }
    })
  }

  const handleDescriptors = useMemo(() => {
    return data.handlers.map((handler) => {
      if (!handler.listen) {
        return null;
      }
      if (handler.listen === 'custom') {
        return `When custom event "${handler.event || ""}" is triggered`;
      }

      const qualifier = qualifierMap[handler.qualifier] || handler.qualifier;
      const event = eventMap[handler.event] || handler.event;
      const props = Object.keys(handler.props || {}).map((key) => {
        return `${key} "${handler.props[key]}"`;
      }).join(' or ');

      return `When ${qualifier} ${props} ${event}`;
    }).filter(f => f);
  }, [data.handlers]);

  if (!clients) return <div>Loading...</div>
  if (!clients.data.length) return (
    <Alert title="No clients found">
      You need at least one client to create a workflow
    </Alert>
  )

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <PageHeader>
        <h1 className="text-2xl font-semibold">Create Workflow</h1>
      </PageHeader>

      <Form onSubmit={handleSubmit} className="space-y-6 bg-white rounded-lg border border-gray-700 p-6">
        {errors?.general && (
          <Alert title="Error">
            {errors.general[0]}
          </Alert>
        )}

        <div className="grid grid-cols-2 gap-4">
          <TextInput
            label="Name"
            id="name"
            value={data.name}
            onChange={(value) => setData({...data, name: value})}
            placeholder="Name"
            errors={errors?.name}
            autoFocus
          />

          <TextInput
            label="Lookup Key"
            id="lookup_key"
            value={data.lookup_key}
            onChange={(value) => setData({...data, lookup_key: value.toLowerCase().replace(/[^a-z0-9-]/g, '-')})}
            placeholder="my-workflow-key"
            errors={errors?.lookup_key}
            helpText="This key will be used to trigger the workflow programmatically"
          />
        </div>

        <div className="space-y-3">
          <div className="flex justify-between items-center border-b border-gray-700 pb-2">
            <div>
              <h2 className="font-medium">Workflow Handlers</h2>
              <p className="text-sm text-gray-700">Define when this workflow should be triggered</p>
            </div>
            <button 
              onClick={addHandler} 
              type="button"
              className="px-3 py-1 text-sm bg-gray-800 text-white hover:bg-gray-700 rounded-md"
            >
              Add Handler
            </button>
          </div>

          {data.handlers.length === 0 ? (
            <div className="text-center py-8 text-gray-700">
              <p className="text-sm">No handlers configured</p>
              <button 
                onClick={addHandler}
                type="button" 
                className="mt-2 text-sm text-blue-700 hover:text-blue-800"
              >
                Add your first handler
              </button>
            </div>
          ) : (
            <div className="space-y-2">
              {data.handlers.map((handler, index) => {
                const handlerErrors = errors ? 
                  Object.entries(errors)
                    .filter(([key]) => key.startsWith(`handlers.${index}.`))
                    .reduce((acc, [key, value]) => {
                      const fieldName = key.split('.')[2];
                      acc[fieldName] = value;
                      return acc;
                    }, {} as Record<string, [string]>) 
                  : undefined;

                return (
                  <HandlerForm
                    key={index}
                    handler={handler}
                    onChange={(handler) => handleHandlerChange(index, handler)}
                    onDelete={() => handleHandlerDelete(index)}
                    errors={handlerErrors}
                  />
                );
              })}
            </div>
          )}
        </div>

        {/* Debug Output */}
        <div className="mt-6 p-3 bg-gray-800 rounded-md">
          <pre className="text-xs text-gray-100 whitespace-pre-wrap">
            {JSON.stringify({
              data,
              errors,
            }, null, 2)}
          </pre>
        </div>

        <div className="pt-4 border-t border-gray-700">
          <button 
            type="submit"
            className="px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 disabled:opacity-50"
            disabled={isSaving}
          >
            {isSaving ? '...Saving' : 'Create Workflow'}
          </button>
        </div>
      </Form>
    </div>
  )
}
