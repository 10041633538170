import AceEditor from "react-ace";
import React from "react";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

type JSONEditorProps = {
  jsonInput: any;
  setJsonInput: any;
  errors?: any;
  onBlur?: () => void;
  rows?: number;
}

export default function JSONEditor({ jsonInput, setJsonInput, errors, onBlur, rows = 10}: JSONEditorProps) {
  return (
    <div>
      <AceEditor
        mode={"json"}
        theme={"monokai"}
        setOptions={{
          useWorker: false
        }}
        onChange={setJsonInput}
        onBlur={onBlur}
        value={jsonInput}
        name="jsonInput"
        width={'100%'}
        editorProps={{
          $blockScrolling: true,
        }}
        height={rows * 20 + 'px'}
      />
      {errors && <div className="text-red-500">{errors}</div>}
    </div>
  )
}
