import { Table, Heading, AlertDialog, Button } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Loader2, Trash2 } from "lucide-react";
import { useState } from "react";
import apiClient from "~/lib/apiClient";

interface Agent {
  id: string;
  lookup_key: string;
  created_at: string;
  last_seen_at: string;
  status: string;
}

export default function AgentsListPage() {
  const { data: agents, error, isLoading, mutate } = useSWR<{ data: Agent[] }>('/account/agents', getFetcher);
  const [agentToDelete, setAgentToDelete] = useState<Agent | null>(null);

  const handleDelete = async (agent: Agent) => {
    try {
      await apiClient.delete(`/account/agents/${agent.lookup_key}`);
      await mutate();
      setAgentToDelete(null);
    } catch (error) {
      console.error('Error deleting agent:', error);
    }
  };

  if (error) {
    return (
      <div className="rounded-lg border border-red-200 bg-red-50 p-4">
        <div className="text-sm text-red-600">
          Error loading agents: {error.message}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="p-10 space-y-6">
      <Heading size="6">Agents</Heading>

      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Agent ID</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Lookup Key</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Last Seen</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {agents?.data.map((agent) => (
            <Table.Row key={agent.id}>
              <Table.Cell>{agent.id}</Table.Cell>
              <Table.Cell>{agent.lookup_key}</Table.Cell>
              <Table.Cell>
                <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                  agent.status === 'active' ? 'bg-green-50 text-green-700' : 'bg-gray-100 text-gray-700'
                }`}>
                  {agent.status}
                </span>
              </Table.Cell>
              <Table.Cell>
                {new Date(agent.created_at).toLocaleDateString()} at{' '}
                {new Date(agent.created_at).toLocaleTimeString()}
              </Table.Cell>
              <Table.Cell>
                {agent.last_seen_at ? (
                  <>
                    {new Date(agent.last_seen_at).toLocaleDateString()} at{' '}
                    {new Date(agent.last_seen_at).toLocaleTimeString()}
                  </>
                ) : (
                  'Never'
                )}
              </Table.Cell>
              <Table.Cell>
                <AlertDialog.Root open={agentToDelete?.id === agent.id} onOpenChange={(open) => !open && setAgentToDelete(null)}>
                  <AlertDialog.Trigger>
                    <Button color="red" variant="soft" onClick={() => setAgentToDelete(agent)}>
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content>
                    <AlertDialog.Title>Delete Agent</AlertDialog.Title>
                    <AlertDialog.Description>
                      Are you sure you want to delete this agent? This action cannot be undone.
                    </AlertDialog.Description>
                    <div className="mt-4 flex justify-end gap-3">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">Cancel</Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Action>
                        <Button variant="solid" color="red" onClick={() => handleDelete(agent)}>Delete</Button>
                      </AlertDialog.Action>
                    </div>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  );
} 
