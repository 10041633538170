import { useEffect, useState } from 'react';
import * as Form from "@radix-ui/react-form";
import { fetchElements, SimplePaginatedResponse, ELEMENTS_KEY } from '~/api/elements';
import SelectInput from './SelectInput';
import useSWR from 'swr';

export type Element = {
  id: string;
  display_name: string;
  lookup_key: string;
  view: object;
  template: object;
  type: 'paywall_modal';
  mode: 'managed';
};

type ElementSelectProps = {
  value?: string;
  onChange?: (element: Element) => void;
  label?: string;
  errors?: string;
  defaultSelectedId?: string;
};

export default function ElementSelect({ value, onChange, label, errors, defaultSelectedId }: ElementSelectProps) {
  const { data: response, error, mutate } = useSWR<SimplePaginatedResponse<Element>>(ELEMENTS_KEY, fetchElements);
  const isLoading = !response && !error;

  // Auto-select default element when data loads or defaultSelectedId changes
  useEffect(() => {
    if (defaultSelectedId && response?.data) {
      const element = response.data.find(e => e.id === defaultSelectedId);
      if (element && onChange) {
        onChange(element);
      }
    }
  }, [defaultSelectedId, response?.data, onChange]);

  const options = response?.data?.filter(element => element.id)?.map(element => ({
    id: element.id,
    name: element.display_name
  })) || [];

  return (
    <Form.Field name="element">
      <SelectInput
        label={label || 'Select Element'}
        value={value || undefined}
        placeholder="Select an element..."
        onValueChange={(elementId) => {
          const element = response?.data.find(e => e.id === elementId);
          if (element && onChange) {
            onChange(element);
          }
        }}
        options={options}
        errors={errors}
      />
    </Form.Field>
  );
} 