import React, {useEffect, useState} from "react";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import PageHeader from "~/components/PageHeader";
import {get} from "lodash";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import bonjoroStage from "~/stage-json/bonjoro-stages";
import Debug from "~/components/Debug";
import TextInput, { TextInputProps } from "~/components/form/TextInput";
import SelectInput, { SelectInputProps } from "~/components/form/SelectInput";
import ErrorList from "~/components/ErrorList";
import JSONInput, { JSONInputProps } from "~/components/form/JSONInput";
import {Form} from "@radix-ui/react-form";
import Alert from "~/components/Alert";
import {Button, Checkbox, Heading, Link} from "@radix-ui/themes";
import { PaywallIntent } from "~/types";
import { CreatePaywallRequest, UpdatePaywallRequest } from "~/types/api/paywall";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "~/components/ui/breadcrumb";
import { toast } from "react-toastify";
import ElementSelect from "~/components/form/ElementSelect";
import type { Element } from "~/components/form/ElementSelect";
import { createElement } from "~/api/elements";
import ElementModal from "~/components/form/ElementModal";

const INTENT_OPTIONS = [
  {id: PaywallIntent.Upgrade, name: 'Upgrade'},
  {id: PaywallIntent.Expansion, name: 'Expansion'},
]

const MODE_OPTIONS = [
  //payment, setup
  {id: 'payment', name: 'Payment'},
  {id: 'setup', name: 'Setup'},
]
const TYPE_OPTIONS = [
  {id: 'modal', name: 'Modal'},
]

const STAGE_OPTIONS = [
  {id: 'bonjoro', name: 'Bonjoro'},
]

interface SchemesResponse {
  data: Array<{
    id: string;
    name: string;
  }>;
}

export default function PaywallCreatePage() {

  const {workflowId} = useParams();
  const navigate = useNavigate();

  const [selectedElement, setSelectedElement] = useState<Element | null>(null);
  const [isCreatingElement, setIsCreatingElement] = useState(false);
  const [isElementModalOpen, setIsElementModalOpen] = useState(false);
  const [elementToDuplicate, setElementToDuplicate] = useState<Element | null>(null);
  const [elementToEdit, setElementToEdit] = useState<Element | null>(null);

  async function handleElementCreate() {
    if (!data.element) return;
    
    setIsCreatingElement(true);
    try {
      const element = await createElement({
        name: `${data.name} Element`,
        view: JSON.parse(data.element.view),
        template: JSON.parse(data.element.template)
      });
      setSelectedElement(element);
      // Update the data to only store the element ID
      setData({
        ...data,
        element_id: element.id
      });
      toast.success("Element created successfully");
    } catch (error) {
      console.error("Failed to create element:", error);
      toast.error("Failed to create element");
    } finally {
      setIsCreatingElement(false);
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    setErrors([]);
    setIsLoading(true);

    // Create element first if it hasn't been created
    if (!selectedElement && data.element) {
      handleElementCreate().then(() => {
        submitPaywall();
      });
      return;
    }

    submitPaywall();
  }

  function submitPaywall() {
    const payloadData = {
      ...data,
      element_id: selectedElement?.id
    };
    delete payloadData.element;

    apiClient.post(`/intel/flows/${workflowId}/scenarios`, payloadData).then(res => {
      toast.success("Paywall created");
      navigate(`/convert/workflows/${res.data.flow.id}/paywalls`);
    }).catch(err => {
      setIsLoading(false);
      console.error("Failed to create paywall", );
      setErrors(err.response.data.errors || [err.response.data.message]);
    });
  }

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {data: schemes, error: schemesError} = useSWR<SchemesResponse>(`/pricing/schemes`, getFetcher);

  // theres multiple types, 
  // 1. paywall 
  // mode is "tracked" or "managed"
  // if its a plandalf paywall, its managed
  // some attributes are only editable if managed.

  const [data, setData] = useState<CreatePaywallRequest>({
    display_name: 'New Paywall',
    scheme_id: get(schemes, 'data.0.id'),
    intent: PaywallIntent.Upgrade,
    lookup_key: null,
    properties: {
      can_change_interval_on_expansion: false,
    },
    element_id: null,
    conditions: [],
    purchasables: [{
      id: '<line_item_1>',
      object: 'plan',
    }],
  });

  useEffect(() => {
    if (schemes) {
      setData(prev => ({...prev, scheme_id: get(schemes, 'data.0.id')}));
    }
  }, [schemes]);

  function textField(id: string, label: string): TextInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onChange: (value) => setData({...data, [id]: value}),
    }
  }

  function jsonField(id: string, label: string): JSONInputProps {
    const value = get(data, id);
    return {
      id,
      label,
      value: typeof value === 'string' ? JSON.parse(value) : value,
      errors: get(errors, id),
      onChange: (value) => {
        setData({...data, [id]: value});
      },
    }
  }

  function selectField(id: string, label: string, options: any[]): SelectInputProps {
    return {
      id,
      label,
      value: get(data, id),
      errors: get(errors, id),
      onValueChange: (value) => setData({...data, [id]: value}),
      options,
    }
  }

  function handleElementCreated(element: Element) {
    setSelectedElement(element);
    setData({
      ...data,
      element_id: element.id
    });
  }

  return (
    <div className="p-10">
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert/workflows/">Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${workflowId}`}>Upgrade &amp; Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator/>
          <BreadcrumbItem>
            <BreadcrumbPage>Create Paywall</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeader >
        <Heading >Create New Paywall</Heading>
      </PageHeader>

      <Form onSubmit={handleSubmit} className="space-y-2">

        <TextInput {...textField('display_name', 'Name')}/>
        
        <TextInput {...textField('lookup_key', 'Lookup Key')}/>

        <SelectInput {...selectField('scheme_id', 'Pricing Scheme', get(schemes, 'data', []))}/>
        {schemes?.data.length === 0 && (
          <Alert title="No Offerings">No offerings, please create one <Link href={`/catalog/pricing`}>here</Link></Alert>
        )}

        <SelectInput {...selectField('intent', 'Intent', INTENT_OPTIONS)}/>
        {data?.intent === PaywallIntent.Expansion  && (
          <div className="text-xs flex space-x-2.5 mt-1">
            <span>Can select and change interval?</span>
            <Checkbox variant="soft" checked={data.properties?.can_change_interval_on_expansion} onCheckedChange={(state) => setData({ ...data, properties: { ...data.properties, can_change_interval_on_expansion: state as boolean }}) } />
          </div>
        )}

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <Heading size="3">Element</Heading>
            <div className="space-x-2">
              <Button 
                type="button" 
                onClick={() => {
                  setElementToDuplicate(null);
                  setIsElementModalOpen(true);
                }}
              >
                Create New Element
              </Button>
            </div>
          </div>

          <ElementSelect
            value={selectedElement?.id}
            onChange={(element) => {
              console.log('Selected element:', element);
              setSelectedElement(element);
              setData({
                ...data,
                element_id: element.id
              });
            }}
            label="Select Element"
          />
          
          {selectedElement && (
            <>
              <pre className="text-xs">{JSON.stringify(selectedElement, null, 2)}</pre>
              <div className="flex items-center space-x-2">
                <Button 
                  type="button" 
                  variant="soft"
                  onClick={() => {
                    console.log('Editing element:', selectedElement);
                    setElementToEdit(selectedElement);
                    setElementToDuplicate(null);
                    setIsElementModalOpen(true);
                  }}
                >
                  Edit Element
                </Button>
                <Button 
                  type="button" 
                  variant="soft"
                  onClick={() => {
                    setElementToDuplicate(selectedElement);
                    setElementToEdit(null);
                    setIsElementModalOpen(true);
                  }}
                >
                  Duplicate Selected Element
                </Button>
              </div>
            </>
          )}
        </div>

        <JSONInput {...jsonField('conditions', 'Conditions')}/>
        <JSONInput {...jsonField('purchasables', 'Purchasables')}/>

        <ErrorList errors={errors} />

        <div className="flex items-center justify-between">
          <Button loading={isLoading} type="submit">Create</Button>
        </div>

      </Form>

      <ElementModal 
        isOpen={isElementModalOpen}
        onClose={() => {
          setIsElementModalOpen(false);
          setElementToDuplicate(null);
          setElementToEdit(null);
        }}
        onSuccess={(element) => {
          handleElementCreated(element);
          setIsElementModalOpen(false);
          setElementToDuplicate(null);
          setElementToEdit(null);
        }}
        sourceElement={elementToDuplicate}
        elementToEdit={elementToEdit}
      />
      <Debug {...data}/>
    </div>
  )
}
