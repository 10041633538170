import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import PageHeader from "~/components/PageHeader";
import React from "react";
import ResourceCollectionTable from "~/components/ResourceCollectionTable";
import AnchorButton from "~/components/AnchorButton";
import {PlusIcon} from "@heroicons/react/24/outline";
import { Workflow } from "~/types";
import {Badge, Heading, Table} from "@radix-ui/themes";
import { Link } from "react-router-dom";

export default function WorkflowsListPage() {

  const [page, setPage] = React.useState(1);
  const { data: workflows, error, isLoading } = useSWR(`/intel/flows?page=${page}`, getFetcher);

  return (
    <div className="p-10">

      <PageHeader>
        <Heading>Checkout Experiences</Heading>
        <AnchorButton variant="surface" href={`/convert/workflows/create`}>
          <PlusIcon className="w-4 h-4 "/>
          Create New
        </AnchorButton>
      </PageHeader>

      <div className="pb-4">
        
      </div>

      {error && <div>Failed to load</div>}
      {!workflows && <div>Loading...</div>}

      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Products</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Views</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Conversions</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Conv Rate</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {workflows?.data?.map(workflow => (
          <Table.Row key={workflow.id}>
            <Table.Cell>
              <Heading size="2" className="mb-2">
                <Link to={`/convert/workflows/${workflow.id}`} className="block">{workflow.name || workflow.id}</Link>
              </Heading>
              <div className="flex gap-2">
                {workflow.trigger_descriptors.map(d => <Badge color="gray" key={d}>{d}</Badge>)}
              </div>
            </Table.Cell>

            <Table.Cell>
              <Badge variant="surface" color={workflow.scenarios_count === 0 ? 'orange' : 'green'}>
                {workflow.scenarios_count} Active
              </Badge>
            </Table.Cell>
            <Table.Cell>
              <ul>
                {workflow.purchasables.map((purchasable, index) => {
                  return (
                    <li key={index}>
                      <Link to="#">{purchasable.name}</Link>
                    </li>
                  )
                })}
              </ul>
            </Table.Cell>
            <Table.Cell>
              {!workflow.stats?.sessions_count && ('-')}
              {workflow.stats?.sessions_count > 0 && (workflow.stats.sessions_count)}
            </Table.Cell>
            <Table.Cell>
              {!workflow.stats?.conversions_count && ('-')}
              {workflow.stats?.conversions_count > 0 && (workflow.stats.conversions_count)}
            </Table.Cell>
            <Table.Cell>
              {!workflow.stats?.conversion_rate && ('-')}
              {workflow.stats?.conversion_rate > 0 && `${workflow.stats.conversion_rate}%`}
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {/*<div className="py-4">*/}
      {/*  {!isLoading && <ResourceCollectionTable*/}
      {/*    collection={workflows.data}*/}
      {/*    setPage={setPage}*/}
      {/*  />}*/}
      {/*</div>*/}
    </div>
  );
}
