import { Select } from "@radix-ui/themes"
import * as Form from '@radix-ui/react-form';

export type SelectInputProps = Select.RootProps & {
  options: Record<'id' | 'name', string>[];
  helpText?: string;
  errors?: string;
  label?: string;
  id?: string;
  placeholder?: string;
};

export default function SelectInput({ id, name, value, label, options, helpText, errors, placeholder, ...props }: SelectInputProps) {
  function handleChange(value: string) {
    if(!value) return;
    props.onValueChange?.(value);
  }

  return (
    <Form.Field name={name} >
      <Form.Label className="FormLabel">{label}</Form.Label>

      <Select.Root
        name={name}
        value={value}
        {...props}
        onValueChange={handleChange}
      >
        <Select.Trigger variant="surface" style={{ width: '100%' }} placeholder={placeholder} />
        <Select.Content>
          {options?.map((option, i) => (
            <Select.Item key={i} value={option.id}>{option.name}</Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      {errors && (<div className="text-sm mt-2 text-rose-600">{errors[0]}</div>)}
      {helpText && <p className="text-gray-400 text-xs pt-1">{helpText}</p>}
    </Form.Field>
  )
}
