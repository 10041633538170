import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import PageHeader from "~/components/PageHeader";
import {useParams} from "react-router";
import { ValueFormatter } from "~/components/form/TextInput";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import { Workflow, Handler } from "~/types";
import { WorkflowEditRequest } from "~/types/api/workflow";
import HandlerForm from "~/components/workflow/HandlerForm";
import Alert from "~/components/Alert";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb"
import {toast} from "react-toastify";
import Debug from "~/components/Debug";
import * as Dialog from "@radix-ui/react-dialog";
import { Button, Flex } from "@radix-ui/themes";

const DEFAULT_HANDLER: Handler = {
  listen: 'attachment',
  event_name: 'click',
  qualifier: 'findButtonByText',
  props: {
    text: '',
  },
};

export default function WorkflowEditPage() {
  const {workflowId} = useParams();
  const {data: workflow, error: workflowError, isLoading} = useSWR(`/intel/flows/${workflowId}`, getFetcher<Workflow>);
  const [errors, setErrors] = useState<Record<string, [string]> | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<WorkflowEditRequest | null>(null);

  useEffect(() => {
    if (!workflow) return;
    setData({
      name: workflow.name,
      lookup_key: workflow.lookup_key,
      handlers: workflow.handlers,
      images: workflow.images,
    });
  }, [workflow]);

  const handleHandlerChange = (index: number, handler: Handler) => {
    if (!data) return;
    const newHandlers = [...data.handlers];
    newHandlers[index] = handler;
    setData({ ...data, handlers: newHandlers });
  };

  const handleHandlerDelete = (index: number) => {
    if (!data) return;
    const newHandlers = [...data.handlers];
    newHandlers.splice(index, 1);
    setData({ ...data, handlers: newHandlers });
  };

  const addHandler = () => {
    if (!data) return;
    setData({ ...data, handlers: [...data.handlers, { ...DEFAULT_HANDLER }] });
  };

  const handleDelete = async () => {
    try {
      const response = await apiClient.delete(`/intel/flows/${workflowId}`);
      if (response.status === 200 || response.status === 204) {
        toast.success('Workflow deleted successfully');
        navigate('/convert/workflows');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to delete workflow';
      toast.error(errorMessage);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!data) return;
    setErrors(null);
    setIsSaving(true);

    const newData = {
      ...data,
      images: data.images?.map((image) => image.id).filter((id) => id !== null)
    }

    apiClient.put(`/intel/flows/${workflowId}`, newData)
      .then(res => {
        toast.success('Workflow updated');
        navigate(`/convert/workflows/${res.data.id}`);
      })
      .catch(err => {
        setIsSaving(false);
        if (err.response?.status === 422) {
          const responseErrors = err.response.data.errors;
          // Convert error arrays to single-element arrays and flatten nested errors
          const formattedErrors: Record<string, [string]> = {};
          Object.entries(responseErrors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              formattedErrors[key] = [value[0]];
            } else if (typeof value === 'object') {
              // Handle nested errors (e.g., handlers.0.event_name)
              Object.entries(value as Record<string, string[]>).forEach(([nestedKey, nestedValue]) => {
                formattedErrors[`${key}.${nestedKey}`] = [nestedValue[0]];
              });
            }
          });
          setErrors(formattedErrors);
        } else {
          setErrors({ general: [err.response?.data?.message || 'An error occurred'] });
        }
      })
  }

  if (isLoading) return <div>Loading...</div>;
  if (!data) return null;

  return (
    <div className="p-6 max-w-4xl min-w-[768px] mx-auto">
      <PageHeader>
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Edit Workflow</h1>
          <Button color="red" variant="soft" onClick={() => setIsDeleteDialogOpen(true)}>
            Delete Workflow
          </Button>
        </div>
      </PageHeader>
      
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert">Upgrade & Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${workflow.id}`}>{workflow.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Edit</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Form.Root onSubmit={handleSubmit} className="space-y-6 bg-white rounded-lg border border-gray-700 p-6">
        {errors?.general && (
          <Alert title="Error">
            {errors.general[0]}
          </Alert>
        )}

        <div className="grid grid-cols-2 gap-4">
          <TextInput
            label="Name"
            value={data.name}
            onChange={(value) => setData({...data, name: value})}
            errors={errors?.name}
          />

          <TextInput 
            label="Lookup Key"
            value={data.lookup_key}
            onChange={(value) => setData({...data, lookup_key: value})}
            beforeChangeFormatter={[ValueFormatter.slug]}
            errors={errors?.lookup_key}
          />
        </div>

        <div className="space-y-3">
          <div className="flex justify-between items-center border-b border-gray-700 pb-2">
            <div>
              <h2 className="font-medium">Workflow Handlers</h2>
              <p className="text-sm text-gray-700">Define when this workflow should be triggered</p>
            </div>
            <button 
              onClick={addHandler} 
              type="button"
              className="px-3 py-1 text-sm bg-gray-800 text-white hover:bg-gray-700 rounded-md"
            >
              Add Handler
            </button>
          </div>

          {data.handlers.length === 0 ? (
            <div className="text-center py-8 text-gray-700">
              <p className="text-sm">No handlers configured</p>
              <button 
                onClick={addHandler}
                type="button" 
                className="mt-2 text-sm text-blue-700 hover:text-blue-800"
              >
                Add your first handler
              </button>
            </div>
          ) : (
            <div className="space-y-2">
              {data.handlers.map((handler, index) => {
                const handlerErrors = errors ? 
                  Object.entries(errors)
                    .filter(([key]) => key.startsWith(`handlers.${index}.`))
                    .reduce((acc, [key, value]) => {
                      const fieldName = key.split('.')[2];
                      acc[fieldName] = value;
                      return acc;
                    }, {} as Record<string, [string]>) 
                  : undefined;

                return (
                  <HandlerForm
                    key={handler.id || index}
                    handler={handler}
                    onChange={(handler) => handleHandlerChange(index, handler)}
                    onDelete={() => handleHandlerDelete(index)}
                    errors={handlerErrors}
                  />
                );
              })}
            </div>
          )}
        </div>

        {/* Debug Output */}
        <Debug data={data} erorrs={errors} />

        <div className="pt-4 border-t border-gray-700">
          <button 
            type="submit"
            className="px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 disabled:opacity-50"
            disabled={isSaving}
          >
            {isSaving ? '...Saving' : 'Save Changes'}
          </button>
        </div>
      </Form.Root>

      <Dialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <Dialog.Title className="text-lg font-semibold mb-4">Delete Workflow</Dialog.Title>
            <Dialog.Description className="text-gray-700 mb-6">
              Are you sure you want to delete this workflow? This action cannot be undone.
            </Dialog.Description>

            <Flex gap="3" justify="end">
              <Dialog.Close asChild>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button color="red" onClick={handleDelete}>
                Delete
              </Button>
            </Flex>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}
